import './App.css';
import { useState } from 'react';
import { marked } from 'marked';

marked.setOptions({
  breaks: true
})

const renderer = new marked.Renderer();

function App() {
  
  const placeholder = `# Welcome to my Markdown Previewer

  ## This is a level 2 heading
  ### Here is a level 3 heading
  
  This is code, \`<div></div>\`
  
  \`\`\`
  // this is multi-line code:
  
  function exampleFunction(one, two) {
    if (one == '\`\`\`' && two == '\`\`\`') {
      return multiLineCode;
    }
  }
  \`\`\`
  
  Here is **bold**... text!
  Or _italic_.
  Or... **_both!_**
  This is ~~strike through~~.
  
  There's also [links](https://www.freecodecamp.org), and
  > Block Quotes!
  
  Here are tables:
  
  One Header | Two Header | Three Header?
  ------------ | ------------- | -------------
  Under One A | Under Two A | Under Three A
  Under One B | Under Two B | Under Three B
  
  - Here are lists.
    - Bulleted.
       - Indentation levels.
          - Like this.
  
  
  1. Numbered lists.
  1. Yes
  1. And next is embedded images:
  
  ![freeCodeCamp Logo](https://cdn.freecodecamp.org/testable-projects-fcc/images/fcc_secondary.svg)
  `;
      
      const [text, setText] = useState(placeholder);
    
    
      return (
        <div className="text-center px-4">
            <h1 className="p-4">My Markdown Previewer</h1>
            <textarea
              name="text"
              id="editor"
              rows="10"
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="textarea"
              ></textarea>
              <h3 className="mt-3">Output</h3>
              <Preview markdown={text}/>
            </div>
    );
  }
  
  function Preview({markdown}) {
      return(
          <div 
          dangerouslySetInnerHTML={{
             __html: marked(markdown, {renderer: renderer}), 
          }}
          id="preview"
  
          ></div>
      );
  }

export default App;
